#profile {
    margin-block: var(--space-700);
    padding-inline: var(--space-600);
    text-align: center;

    > h1 {
        line-height: 1.5;
        font-family: lyon-display-web, serif;
    }

    > blockquote {
        position: relative;
        width: 100%;
        margin: 0 0 var(--space-500);
        padding: 0 var(--space-600);
        color: var(--color-300);
        font-style: italic;
        line-height: 1.2;
        text-align: center;

        &::before {
            content: '\201C';
            display: inline-block;
            height: var(--space-500);
            margin-bottom: 0.25ex;
            overflow: hidden;
            font-family: initial;
            font-size: var(--space-700);
            line-height: 1;
        }
    }

    > .summary {
        max-width: 36rem;
        margin-top: var(--space-600);
        margin-inline: auto;
    }
}

.avatar {
    --size: 11rem;

    display: inline-block;
    position: relative;

    > picture {
        display: inline-block;
        clip-path: polygon(0 0, 25% 71.14%, 73.29% 95.8%, 100% 0px);

        > img {
            aspect-ratio: 1;
            width: var(--size);
            height: var(--size);
        }
    }

    > .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--black);
        clip-path: polygon(8% 22%, 25% 71.14%, 73.29% 95.8%, 96.02% 13%);
    }
}

#contact {
    &.loading {
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 95%;
            width: 50%;
            height: 5%;
            transform-origin: 50%;
            animation: slide 2s infinite forwards linear;
            background-color: var(--color-500);
        }
    }
}
