@media (prefers-color-scheme: dark) {
    body {
        background-color: var(--black);
        color: var(--white);
    }

    h1,
    h3,
    .employment > h3 {
        color: var(--white);
    }
    h2 {
        color: var(--color-300);
    }
    a:visited {
        color: var(--color-500);
    }
    #profile > picture {
        border-color: var(--white);
    }

    .avatar > .background {
        background-color: var(--white);
    }

    .timeline {
        --timeline-title-color: var(--color-100);
        --timeline-locator-color: var(--color-300);
        --timeline-locator-label-color: var(--color-200);
        --timeline-details-label-color: var(--color-200);
        --timeline-details-title-color: var(--color-200);
        --timeline-details-subtitle-color: var(--color-200);
        --timeline-details-meta-color: var(--color-200);
        --timeline-details-description-color: var(--color-200);
    }
}

@media (prefers-contrast: more) {
    .home .timeline {
        --timeline-title-color: var(--black);
        --timeline-locator-color: var(--black);
        --timeline-locator-label-color: var(--black);
        --timeline-details-label-color: var(--black);
        --timeline-details-title-color: var(--black);
        --timeline-details-subtitle-color: var(--black);
        --timeline-details-meta-color: var(--black);
        --timeline-details-description-color: var(--black);
    }
}
