h2 {
    max-width: var(--max-width);
    margin: auto;
    margin-top: 2rem;
    padding: 0 1rem;
    color: var(--color-300);
    font-family: lyon-display-web, serif;
}

strong {
    font-weight: bold;
    margin-inline-end: 1ch;
    opacity: 0.65;
}

.summary {
    max-width: var(--max-width);
    margin: auto;
    padding: 1rem;
}

.skills {
    max-width: var(--max-width);
    margin: auto;
    padding: 1rem 1rem 1rem 2rem;
}

.timeline {
    --timeline-title-color: var(--color-200);
    --timeline-locator-color: var(--color-100);
    --timeline-locator-label-color: var(--color-400);
    --timeline-details-label-color: var(--color-200);
    --timeline-details-title-color: var(--color-400);
    --timeline-details-subtitle-color: var(--color-300);
    --timeline-details-meta-color: var(--color-400);
    --timeline-details-description-color: var(--color-400);
    --timeline-border-width: 0.125rem;
    --timeline-spacing: var(--space-500);
    --timeline-location-w: var(--timeline-spacing);
    display: grid;
    grid-gap: 0 var(--timeline-spacing);
    grid-template-columns: minmax(5rem, auto) 1fr;
    max-width: var(--max-width);
    margin: auto;
    padding: var(--timeline-spacing);
}

%divider-first {
    top: auto;
    bottom: 0;
    height: calc(100% - var(--timeline-spacing));

    &::before {
        top: 0;
    }
}

%divider-last {
    &::after {
        position: absolute;
        right: calc(var(--timeline-spacing) / 2 * -1 - var(--timeline-border-width) * 1.5);
        bottom: 0;
        width: 0;
        height: 0;
        border-top: calc(var(--timeline-spacing) / 2 + var(--timeline-border-width)) solid;
        border-right: calc(var(--timeline-spacing) / 2 + var(--timeline-border-width)) solid;
        border-bottom: calc(var(--timeline-spacing) / 2 + var(--timeline-border-width)) solid;
        border-left: calc(var(--timeline-spacing) / 2 + var(--timeline-border-width)) solid;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: var(--timeline-locator-color);
        border-left-color: transparent;
        content: '';
    }
}

.period {
    display: inline-flex;
    position: relative;
    flex: 0 0 auto;
    justify-content: flex-end;
    padding: 0 var(--timeline-spacing) var(--timeline-spacing) 0;
    color: var(--timeline-locator-label-color);

    &:first-child {
        > .divider {
            @extend %divider-first;
        }
    }

    &:nth-last-child(2) {
        > .divider {
            @extend %divider-last;
        }
    }
}

.divider {
    position: absolute;
    right: 0;
    left: 0;
    height: 100%;
    border-right: var(--timeline-border-width) solid var(--timeline-locator-color);

    &::before {
        position: absolute;
        top: var(--timeline-spacing);
        right: calc((var(--timeline-location-w) + var(--timeline-border-width)) / 2 * -1);
        width: var(--timeline-location-w);
        border-top: var(--timeline-border-width) solid var(--timeline-locator-color);
        border-radius: 1px;
        content: '';
    }
}

.employment {
    padding-bottom: var(--space-600);

    > h3 {
        margin: 0;
        color: var(--black);
        font-weight: bold;
        font-size: calc(var(--space-500) + var(--space-200));
    }
}

.position {
    color: var(--timeline-details-meta-color);
    font-size: var(--space-500);
}

.description {
    color: var(--timeline-details-subtitle-color);
    font-style: italic;
    font-size: var(--space-500);

    ul {
        padding-inline-start: 1rem;
    }

    &:has(+ .technologies) {
        margin-bottom: 1rem;
    }
}

.type {
    color: var(--timeline-details-description-color);
    font-size: var(--space-400);
}
