@page {
    size: A4;
    margin: 1cm;
}

@media print {
    :root {
        --max-width: 100%;
    }

    html {
        font-size:55%;
        size: A4 portrait;
        margin: 0;
    }

    body {
        background-color: var(--white);
        color: var(--black);
    }

    #profile {
        > .avatar {
            margin: var(--space-300);
        }

        > h1 {
            align-self: start;
        }
    }

    blockquote {
        display: none;
    }

    .links {
        display: none;
    }

    .grecaptcha-badge {
        display: none !important;
    }
}
