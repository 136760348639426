:root {
    /* Colors */
    --black: hsla(235, 21%, 21%, 1);
    --color-100: hsla(197, 24%, 94%, 1);
    --color-200: hsla(213, 18%, 78%, 1);
    --color-300: hsla(218, 17%, 62%, 1);
    --color-400: hsla(225, 13%, 42%, 1);
    --color-500: hsla(207, 80%, 74%, 1);
    --color-600: hsla(207, 87%, 54%, 1);
    --color-700: hsla(210, 82%, 44%, 1);
    --color-800: hsla(213, 96%, 34%, 1);
    --white: hsl(0, 100%, 100%);
    /* Fonts */
    --font-size-100: 0.694rem;
    --font-size-200: 0.833rem;
    --font-size-300: 1rem;
    --font-size-400: 1.2rem;
    --font-size-500: 1.44rem;
    --font-size-600: 1.728rem;
    --font-size-700: 2.074rem;
    --font-size-800: 2.488rem;
    --font-size-900: 2.986rem;
    /* Spacing */
    --space-100: 0.125rem;
    --space-200: 0.25rem;
    --space-300: 0.5rem;
    --space-400: 0.75rem;
    --space-500: 1rem;
    --space-600: 2rem;
    --space-700: 3rem;
    --space-800: 4rem;
    --space-900: 5rem;

    --max-width: 50rem;
}

@font-face {
    font-style: normal;
    font-weight: 400;
    src: url(../font/averta-regular-webfont.woff2) format('woff2');
    font-family: Averta;
    font-display: swap;
}
@font-face {
    font-style: normal;
    font-weight: 700;
    src: url(../font/averta-semibold-webfont.woff2) format('woff2');
    font-family: Averta;
    font-display: swap;
}
@font-face {
    font-style: italic;
    font-weight: 400;
    src: url(../font/averta-regularitalic-webfont.woff2) format('woff2');
    font-family: Averta;
    font-display: swap;
}
@font-face {
    font-style: normal;
    font-weight: 700;
    src: url(../font/LyonDisplay-Bold-Web.woff2) format('woff2');
    font-stretch: normal;
    font-family: lyon-display-web;
    font-display: swap;
}

html {
    font-size: 100%;
}

body {
    background-color: var(--white);
    color: var(--black);
    font-weight: 400;
    line-height: 1.75;
    font-family: Averta, sans-serif;
}

h1 {
    margin-top: 0;
    font-size: var(--font-size-800);
}

h2 {
    font-size: var(--font-size-700);
}

h3 {
    font-size: var(--font-size-600);
}

h4 {
    font-size: var(--font-size-500);
}

h5 {
    font-size: var(--font-size-400);
}

small {
    font-size: 0.833rem;
}

button,
a {
    color: var(--color-700);
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}
a:visited {
    color: var(--color-800);
}

:focus {
    outline: none;
}

:focus-visible {
    z-index: 1;
    outline: 0.125rem dashed orange;
    outline-offset: 0.125rem;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

@keyframes slide {
    from {
        right: 100%;
    }
    to {
        right: -100%;
    }
}
