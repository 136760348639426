:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

menu {
  list-style: none;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

:root {
  --black: #2a2c41;
  --color-100: #ecf1f3;
  --color-200: #bdc6d1;
  --color-300: #8e9aaf;
  --color-400: #5d6479;
  --color-500: #88c2f2;
  --color-600: #2494f0;
  --color-700: #1470cc;
  --color-800: #034eaa;
  --white: #fff;
  --font-size-100: .694rem;
  --font-size-200: .833rem;
  --font-size-300: 1rem;
  --font-size-400: 1.2rem;
  --font-size-500: 1.44rem;
  --font-size-600: 1.728rem;
  --font-size-700: 2.074rem;
  --font-size-800: 2.488rem;
  --font-size-900: 2.986rem;
  --space-100: .125rem;
  --space-200: .25rem;
  --space-300: .5rem;
  --space-400: .75rem;
  --space-500: 1rem;
  --space-600: 2rem;
  --space-700: 3rem;
  --space-800: 4rem;
  --space-900: 5rem;
  --max-width: 50rem;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  src: url("averta-regular-webfont.b1e1a3ef.woff2") format("woff2");
  font-family: Averta;
  font-display: swap;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  src: url("averta-semibold-webfont.f11df573.woff2") format("woff2");
  font-family: Averta;
  font-display: swap;
}

@font-face {
  font-style: italic;
  font-weight: 400;
  src: url("averta-regularitalic-webfont.771b64a6.woff2") format("woff2");
  font-family: Averta;
  font-display: swap;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  src: url("LyonDisplay-Bold-Web.915e4161.woff2") format("woff2");
  font-stretch: normal;
  font-family: lyon-display-web;
  font-display: swap;
}

html {
  font-size: 100%;
}

body {
  background-color: var(--white);
  color: var(--black);
  font-family: Averta, sans-serif;
  font-weight: 400;
  line-height: 1.75;
}

h1 {
  font-size: var(--font-size-800);
  margin-top: 0;
}

h2 {
  font-size: var(--font-size-700);
}

h3 {
  font-size: var(--font-size-600);
}

h4 {
  font-size: var(--font-size-500);
}

h5 {
  font-size: var(--font-size-400);
}

small {
  font-size: .833rem;
}

button, a {
  color: var(--color-700);
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

a:visited {
  color: var(--color-800);
}

:focus {
  outline: none;
}

:focus-visible {
  z-index: 1;
  outline-offset: .125rem;
  outline: .125rem dashed orange;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes slide {
  from {
    right: 100%;
  }

  to {
    right: -100%;
  }
}

#profile {
  margin-block: var(--space-700);
  padding-inline: var(--space-600);
  text-align: center;
}

#profile > h1 {
  font-family: lyon-display-web, serif;
  line-height: 1.5;
}

#profile > blockquote {
  margin: 0 0 var(--space-500);
  padding: 0 var(--space-600);
  color: var(--color-300);
  text-align: center;
  width: 100%;
  font-style: italic;
  line-height: 1.2;
  position: relative;
}

#profile > blockquote:before {
  content: "“";
  height: var(--space-500);
  font-family: initial;
  font-size: var(--space-700);
  margin-bottom: .25ex;
  line-height: 1;
  display: inline-block;
  overflow: hidden;
}

#profile > .summary {
  margin-top: var(--space-600);
  max-width: 36rem;
  margin-inline: auto;
}

.avatar {
  --size: 11rem;
  display: inline-block;
  position: relative;
}

.avatar > picture {
  clip-path: polygon(0 0, 25% 71.14%, 73.29% 95.8%, 100% 0);
  display: inline-block;
}

.avatar > picture > img {
  aspect-ratio: 1;
  width: var(--size);
  height: var(--size);
}

.avatar > .background {
  background-color: var(--black);
  clip-path: polygon(8% 22%, 25% 71.14%, 73.29% 95.8%, 96.02% 13%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#contact.loading {
  position: relative;
  overflow: hidden;
}

#contact.loading:before {
  content: "";
  transform-origin: 50%;
  background-color: var(--color-500);
  width: 50%;
  height: 5%;
  animation: 2s linear infinite forwards slide;
  display: block;
  position: absolute;
  top: 95%;
}

h2 {
  max-width: var(--max-width);
  color: var(--color-300);
  margin: auto;
  margin-top: 2rem;
  padding: 0 1rem;
  font-family: lyon-display-web, serif;
}

strong {
  opacity: .65;
  margin-inline-end: 1ch;
  font-weight: bold;
}

.summary {
  max-width: var(--max-width);
  margin: auto;
  padding: 1rem;
}

.skills {
  max-width: var(--max-width);
  margin: auto;
  padding: 1rem 1rem 1rem 2rem;
}

.timeline {
  --timeline-title-color: var(--color-200);
  --timeline-locator-color: var(--color-100);
  --timeline-locator-label-color: var(--color-400);
  --timeline-details-label-color: var(--color-200);
  --timeline-details-title-color: var(--color-400);
  --timeline-details-subtitle-color: var(--color-300);
  --timeline-details-meta-color: var(--color-400);
  --timeline-details-description-color: var(--color-400);
  --timeline-border-width: .125rem;
  --timeline-spacing: var(--space-500);
  --timeline-location-w: var(--timeline-spacing);
  grid-gap: 0 var(--timeline-spacing);
  max-width: var(--max-width);
  padding: var(--timeline-spacing);
  grid-template-columns: minmax(5rem, auto) 1fr;
  margin: auto;
  display: grid;
}

.period:first-child > .divider {
  height: calc(100% - var(--timeline-spacing));
  top: auto;
  bottom: 0;
}

.period:first-child > .divider:before {
  top: 0;
}

.period:nth-last-child(2) > .divider:after {
  right: calc(var(--timeline-spacing) / 2 * -1 - var(--timeline-border-width) * 1.5);
  border-top: calc(var(--timeline-spacing) / 2 + var(--timeline-border-width)) solid;
  border-right: calc(var(--timeline-spacing) / 2 + var(--timeline-border-width)) solid;
  border-bottom: calc(var(--timeline-spacing) / 2 + var(--timeline-border-width)) solid;
  border-left: calc(var(--timeline-spacing) / 2 + var(--timeline-border-width)) solid;
  border-top-color: #0000;
  border-right-color: #0000;
  border-bottom-color: var(--timeline-locator-color);
  content: "";
  border-left-color: #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0;
}

.period {
  padding: 0 var(--timeline-spacing) var(--timeline-spacing) 0;
  color: var(--timeline-locator-label-color);
  flex: none;
  justify-content: flex-end;
  display: inline-flex;
  position: relative;
}

.divider {
  border-right: var(--timeline-border-width) solid var(--timeline-locator-color);
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.divider:before {
  top: var(--timeline-spacing);
  right: calc((var(--timeline-location-w)  + var(--timeline-border-width)) / 2 * -1);
  width: var(--timeline-location-w);
  border-top: var(--timeline-border-width) solid var(--timeline-locator-color);
  content: "";
  border-radius: 1px;
  position: absolute;
}

.employment {
  padding-bottom: var(--space-600);
}

.employment > h3 {
  color: var(--black);
  font-weight: bold;
  font-size: calc(var(--space-500)  + var(--space-200));
  margin: 0;
}

.position {
  color: var(--timeline-details-meta-color);
  font-size: var(--space-500);
}

.description {
  color: var(--timeline-details-subtitle-color);
  font-style: italic;
  font-size: var(--space-500);
}

.description ul {
  padding-inline-start: 1rem;
}

.description:has( + .technologies) {
  margin-bottom: 1rem;
}

.type {
  color: var(--timeline-details-description-color);
  font-size: var(--space-400);
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--black);
    color: var(--white);
  }

  h1, h3, .employment > h3 {
    color: var(--white);
  }

  h2 {
    color: var(--color-300);
  }

  a:visited {
    color: var(--color-500);
  }

  #profile > picture {
    border-color: var(--white);
  }

  .avatar > .background {
    background-color: var(--white);
  }

  .timeline {
    --timeline-title-color: var(--color-100);
    --timeline-locator-color: var(--color-300);
    --timeline-locator-label-color: var(--color-200);
    --timeline-details-label-color: var(--color-200);
    --timeline-details-title-color: var(--color-200);
    --timeline-details-subtitle-color: var(--color-200);
    --timeline-details-meta-color: var(--color-200);
    --timeline-details-description-color: var(--color-200);
  }
}

@media (prefers-contrast: more) {
  .home .timeline {
    --timeline-title-color: var(--black);
    --timeline-locator-color: var(--black);
    --timeline-locator-label-color: var(--black);
    --timeline-details-label-color: var(--black);
    --timeline-details-title-color: var(--black);
    --timeline-details-subtitle-color: var(--black);
    --timeline-details-meta-color: var(--black);
    --timeline-details-description-color: var(--black);
  }
}

@page {
  size: A4;
  margin: 1cm;
}

@media print {
  :root {
    --max-width: 100%;
  }

  html {
    size: A4 portrait;
    margin: 0;
    font-size: 55%;
  }

  body {
    background-color: var(--white);
    color: var(--black);
  }

  #profile > .avatar {
    margin: var(--space-300);
  }

  #profile > h1 {
    align-self: start;
  }

  blockquote, .links {
    display: none;
  }

  .grecaptcha-badge {
    display: none !important;
  }
}

.h-captcha {
  background-color: #fff;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}
/*# sourceMappingURL=index.391783ef.css.map */
